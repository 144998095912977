export const locationsList = [
    {
        id: 0,
        state: "Dallas",
        address: 'Carretera 129, KM 26.7 Bo. Mijan, Lares',
        phone : '(939) 252 4501'
    },
    {
        id: 1,
        state: "Dallas",
        address: 'Carretera 446, KM 2.4 Bo. Galateo Bajo, Isabela',
        phone : '(939) 252 45012'
    },
]